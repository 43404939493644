<template>
  <div>
    <div>
      <h1 class="h4 mb-3 text-gray-800">Dashboard</h1>
      <div class="row mb-4">
        <div class="col-md-3">
          <div class="card border-left-primary shadow h-100 py-2">
            <div class="card-body pt-2 pb-0">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="
                      text-xs
                      font-weight-bold
                      text-primary text-uppercase
                      mb-1
                    "
                  >
                    <router-link :to="{ name: 'admin.users.index', query: { show: 1 }}">Traders</router-link>
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{ stats.traders }}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-users fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card border-left-success shadow h-100 py-2">
            <div class="card-body pt-2 pb-0">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="
                      text-xs
                      font-weight-bold
                      text-success text-uppercase
                      mb-1
                    "
                  >
                    <router-link :to="{ name: 'admin.users.index', query: { show: 2 }}"
                      >Signal Users</router-link
                    >
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{ stats.signals }}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-bar-chart fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card border-left-warning shadow h-100 py-2">
            <div class="card-body pt-2 pb-0">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="
                      text-xs
                      font-weight-bold
                      text-primary text-uppercase
                      mb-1
                    "
                  >
                    <router-link :to="{ name: 'admin.users.index', query: { show: 6 }}"
                      >Academy Learners</router-link
                    >
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{ stats.academy }}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-money fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card border-left-danger shadow h-100 py-2">
            <div class="card-body pt-2 pb-0">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="
                      text-xs
                      font-weight-bold
                      text-secondary text-uppercase
                      mb-1
                    "
                  >
                    <router-link :to="{ name: 'admin.users.index', query: { show: 5 }}">Moderators</router-link>
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{ stats.moderators }}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-user fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header">
              <select class="float-right" v-model="report_type">
                <option value="daily">Daily</option>
                <option value="monthly">Monthly</option>
              </select>
              <h1 class="h5 font-weight-bold text-dark-700">Income Report <small>last {{ report_type == 'daily' ? '30 days' : '12 months' }}</small></h1>
            </div>
            <div class="card-body" v-if="! loading">
              <div class="row">
                <div class="col-md-6">
                  <h1 class="h5">Total USD: {{ report_type == 'daily' ? income_report.usd : monthly_report.usd }}</h1>
                </div>
                <div class="col-md-6 text-right">
                  <h1 class="h5">Total KES: {{ report_type == 'daily' ? income_report.kes : monthly_report.kes }}</h1>
                </div>
              </div>
              <div v-show="report_type == 'daily'">
                <chart :data="income_report.graph_data" type="line" :height="`150px`" :correlation="false" />
              </div>
              <div v-show="report_type == 'monthly'">
                <chart :data="monthly_report.graph_data" type="line" :height="`150px`" :correlation="false" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-header">
              <h1 class="h5 font-weight-bold text-dark-700">Income Sources <small>last 30 days</small></h1>
            </div>
            <div class="card-body" v-if="! loading">
              <div class="row justify-content-end mb-4">
                <div class="col-md-6">
                  <select class="form-control" v-model="show_source">
                    <option value="usd">USD</option>
                    <option value="kes">KES</option>
                  </select>
                </div>
              </div>
              <div v-show="show_source == 'usd'">
                <chart :data="income_report.usd_sources_data" type="pie" :height="`160px`" :correlation="false" />
              </div>
              <div v-show="show_source == 'kes'">
                <chart :data="income_report.kes_sources_data" type="pie" :height="`160px`" :correlation="false" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="py-2 px-3">
              <router-link
                :to="{ name: 'admin.discussions.create' }"
                class="btn btn-primary btn-sm px-2 py-2 float-right"
                >Start New</router-link
              >
              <h3 class="h4 card-title"><router-link :to="{ name: 'moderator.discussions.index' }" class="text-dark">Active Discussions</router-link></h3>
              <table class="table table-responsive border-bottom table-condensed">
                <tbody>
                  <tr v-for="(topic, i) in topics" :key="`discussion-${i}`">
                    <td width="15%">
                      <router-link
                        :to="{
                          name: 'admin.discussions.show',
                          params: { id: topic.id },
                        }"
                      >
                        <img
                          :src="topic.author.avatar"
                          :alt="topic.title"
                          class="avatar"
                        />
                      </router-link>
                    </td>
                    <td class="border-right">
                      <router-link
                        :to="{
                          name: 'admin.discussions.show',
                          params: { id: topic.id },
                        }"
                      >
                        <p
                          :class="`mb-0 ${
                            loading ? 'skeleton skeleton-text' : ''
                          }`"
                        >
                          {{ topic.title }}
                        </p>
                        <p class="mb-0">
                          <small class="text-muted">{{ $localizer.parse(topic.date) }}</small>
                        </p>
                      </router-link>
                    </td>
                    <td width="10%">
                      <router-link
                        :to="{
                          name: 'admin.discussions.show',
                          params: { id: topic.id },
                        }"
                      >
                        <p class="mb-0 text-black">Replies</p>
                        <p class="mb-0">
                          {{ topic.comments }}
                        </p>
                      </router-link>
                    </td>
                  </tr>
                  <tr v-if="topics.length == 0">
                    <td>
                      There is no data to display
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="px-3 py-2">
              <router-link
                :to="{ name: 'admin.conference.create' }"
                class="btn btn-primary btn-sm px-2 py-2 float-right"
                >Start New</router-link
              >
              <h3 class="h4 card-title"><router-link :to="{ name: 'admin.conference.index' }" class="text-dark">Daily Conference</router-link></h3>
              <table class="table table-responsive">
                <tbody>
                  <tr v-for="(video, i) in conference" :key="`video-${i}`">
                    <td width="60%">
                      <p :class="loading ? 'skeleton skeleton-text mb-0' : ''">
                        <router-link
                          :class="loading ? 'skeleton skeleton-text' : ''"
                          :to="{
                            name: 'admin.conference.show',
                            params: { id: video.id },
                          }"
                          >{{ video.title }}</router-link
                        >
                      </p>
                    </td>
                    <td>
                      <p :class="loading ? 'skeleton skeleton-text mb-0' : ''">
                        {{ $localizer.parse(video.date) }}
                      </p>
                    </td>
                  </tr>
                  <tr v-if="conference.length == 0">
                    <td colspan="2">There is no data to display</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from '@/components/charts/Chart'

export default {
  components: {
    Chart
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      topics: [],
      loading: true,
      conference: [],
      stats: {},
      income_report: {},
      monthly_report: {},
      show_source: 'usd',
      report_type: 'daily'
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/admin/home").then((response) => {
        this.topics = response.data.topics;
        this.conference = response.data.conference;
        this.loading = false;
        this.stats = response.data.stats;
        this.income_report = response.data.income_report;
        this.monthly_report = response.data.monthly_report;
      });
    },
  },
};
</script>
